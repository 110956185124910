<template>
    <div class="modal-wrap">
        <h1 class="tit-h1 d-flex">
            {{ param.headerTitle }}
            <div class="ml-auto align-self-center">
                <v-icon v-if="p01" x-small class="svg-close20" @click="$emit('hideExcel')"></v-icon>
                <v-icon v-if="p02 || p03" x-small class="svg-close20" @click="$emit('hide')"></v-icon>
            </div>
        </h1>
        <div class="modal-box">
            <template v-if="p01">
                <div class="box-ct">
                    <div class="table-form d-flex">
                        <table>
                            <colgroup>
                                <col width="15%" />
                                <col width="35%" />
                                <col width="15%" />
                                <col width="35%" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        <v-badge dot color="#EF9191">건설사명</v-badge>
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-autocomplete
                                                :items="ctcpTypeCds"
                                                item-text="CD_NM"
                                                item-value="CD"
                                                outlined
                                                @change="fnChnCtcpCd(CTCP_CODE)"
                                                v-model="CTCP_CODE"
                                                hide-details
                                                placeholder="선택"
                                            >
                                            </v-autocomplete>
                                        </div>
                                    </td>
                                    <th scope="row">
                                        <v-badge dot color="#EF9191">사업지명</v-badge>
                                    </th>
                                    <td>
                                        <div class="form-inp full">
                                            <v-autocomplete
                                                :items="bussTypeCds"
                                                item-text="CD_NM"
                                                item-value="CD"
                                                outlined
                                                v-model="BUSS_CODE"
                                                :disabled="bussTypeCds.length == 0 ? true : false"
                                                placeholder="선택"
                                            >
                                            </v-autocomplete>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <v-badge dot color="#EF9191">엑셀파일</v-badge>
                                    </th>
                                    <td colspan="3">
                                        <div class="form-inp full">
                                            <v-text-field 
                                                class="form-inp table-form-tag form-image" 
                                                ref="excelFile" 
                                                outlined hide-details
                                                type="file"
                                                style="width:90%;" 
                                                v-model="EXCEL_FILE">
                                            </v-text-field>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn 
                                                        dark small 
                                                        class="btn-main-refresh pa-0 ml-2" 
                                                        min-width="32px" 
                                                        height="32px" 
                                                        plain
                                                        v-bind="attrs"
                                                        @click="fnExcelUpload"
                                                        v-on="on">
                                                        <v-icon small class="svg-main-down"></v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>엑셀파일 업로드</span>
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
            <template v-if="p02">
                <main>
                    <div class="box-ct">
                        <div class="table-form d-flex">
                            <table>
                                <colgroup>
                                    <col width="10%" />
                                    <col width="40%" />
                                    <col width="10%" />
                                    <col width="40%" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">건설사명</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-autocomplete
                                                    :items="ctcpTypeCds"
                                                    item-text="CD_NM"
                                                    item-value="CD"
                                                    outlined
                                                    placeholder="선택"
                                                    @change="fnChnCtcpCd(detaInfoData.CTCP_CODE)"
                                                    v-model="detaInfoData.CTCP_CODE"
                                                    hide-details
                                                >
                                                </v-autocomplete>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">사업지명</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-autocomplete
                                                    :items="bussTypeCds"
                                                    item-text="CD_NM"
                                                    item-value="CD"
                                                    outlined
                                                    placeholder="선택"
                                                    v-model="detaInfoData.BUSS_CODE"
                                                    :disabled="bussTypeCds.length == 0 ? true : false"
                                                >
                                                </v-autocomplete>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">동</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.DONG"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">호수</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.HOSU"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">주소</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.ADDRESS"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">주민등록번호</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.CUST_RES_NO"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">고객명</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.CONTR_CUST_NAME"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">전화번호</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.CONTR_CUST_PHONE"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">마케팅활용동의</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-checkbox 
                                                    :on-icon="'svg-chk-on'" 
                                                    :off-icon="'svg-chk-off'" 
                                                    class="form-inp table-form-tag"
                                                    v-model="detaInfoData.MKTG_AGRE_YN">
                                                </v-checkbox>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">개인정보수집동의</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-checkbox 
                                                    :on-icon="'svg-chk-on'" 
                                                    :off-icon="'svg-chk-off'" 
                                                    class="form-inp table-form-tag"
                                                    v-model="detaInfoData.PRSN_AGRE_YN">
                                                </v-checkbox>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            초기성향
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-select
                                                    class="form-inp full"
                                                    :items="userPpsityCd"
                                                    item-text="CD_NM"
                                                    item-value="CD"
                                                    outlined
                                                    hide-details
                                                    placeholder="선택하세요"
                                                    v-model="detaInfoData.CUST_PPST_CD"
                                                >
                                                </v-select>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            진행상태
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-select
                                                    class="form-inp full"
                                                    :items="cslStatusItems"
                                                    item-text="CD_NM"
                                                    item-value="CD"
                                                    outlined
                                                    hide-details
                                                    placeholder="선택하세요"
                                                    v-model="detaInfoData.PROC_STAT_CD"
                                                >
                                                </v-select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
                <footer>
                    <h1 class="d-flex">
                        <div class="ml-auto">
                            <v-btn outlined small class="btn-default mt-2" @click="fnModiConfirm">{{ BTN_TEXT }}</v-btn>
                        </div>
                    </h1>
                </footer>
            </template>
            <template v-if="p03">
                <main>
                    <div class="box-ct">
                        <div class="table-form d-flex">
                            <table>
                                <colgroup>
                                    <col width="10%" />
                                    <col width="40%" />
                                    <col width="10%" />
                                    <col width="40%" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">건설사명</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-autocomplete
                                                    :items="ctcpTypeCds"
                                                    item-text="CD_NM"
                                                    item-value="CD"
                                                    outlined
                                                    placeholder="선택"
                                                    @change="fnChnCtcpCd(detaInfoData.CTCP_CODE)"
                                                    v-model="detaInfoData.CTCP_CODE"
                                                    hide-details
                                                >
                                                </v-autocomplete>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">사업지명</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-autocomplete
                                                    :items="bussTypeCds"
                                                    item-text="CD_NM"
                                                    item-value="CD"
                                                    outlined
                                                    placeholder="선택"
                                                    v-model="detaInfoData.BUSS_CODE"
                                                    :disabled="bussTypeCds.length == 0 ? true : false"
                                                >
                                                </v-autocomplete>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <!-- <v-badge dot color="#EF9191">주소</v-badge> -->
                                            주소
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.ADDRESS"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            <!-- <v-badge dot color="#EF9191">주민등록번호</v-badge> -->
                                            주민등록번호
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.CUST_RES_NO"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">카카오톡 고객명</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.CUST_NAME"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">카카오톡 전화번호</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-text-field
                                                    class="form-inp full"
                                                    outlined
                                                    hide-details
                                                    v-model="detaInfoData.CUST_PHONE"
                                                >
                                                </v-text-field>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">마케팅활용동의</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-checkbox 
                                                    :on-icon="'svg-chk-on'" 
                                                    :off-icon="'svg-chk-off'" 
                                                    class="form-inp table-form-tag"
                                                    v-model="detaInfoData.MKTG_AGRE_YN">
                                                </v-checkbox>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">개인정보수집동의</v-badge>
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-checkbox 
                                                    :on-icon="'svg-chk-on'" 
                                                    :off-icon="'svg-chk-off'" 
                                                    class="form-inp table-form-tag"
                                                    v-model="detaInfoData.PRSN_AGRE_YN">
                                                </v-checkbox>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            초기성향
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-select
                                                    class="form-inp full"
                                                    :items="userPpsityCd"
                                                    item-text="CD_NM"
                                                    item-value="CD"
                                                    outlined
                                                    hide-details
                                                    placeholder="선택하세요"
                                                    v-model="detaInfoData.CUST_PPST_CD"
                                                >
                                                </v-select>
                                            </div>
                                        </td>
                                        <th scope="row">
                                            진행상태
                                        </th>
                                        <td>
                                            <div class="form-inp full">
                                                <v-select
                                                    class="form-inp full"
                                                    :items="cslStatusItems"
                                                    item-text="CD_NM"
                                                    item-value="CD"
                                                    outlined
                                                    hide-details
                                                    placeholder="선택하세요"
                                                    v-model="detaInfoData.PROC_STAT_CD"
                                                >
                                                </v-select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
                <footer>
                    <h1 class="d-flex">
                        <div class="ml-auto">
                            <v-btn outlined small class="btn-default mt-2" @click="fnModiConfirm">{{ BTN_TEXT }}</v-btn>
                        </div>
                    </h1>
                </footer>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from "axios"

    export default {
        name: 'MENU_OMP0410',

        components: {},

        props : {
            param:{
                type: Object,
            },
            p01: {
                type: Boolean,
                default: false,
            },
            p02: {
                type: Boolean,
                default: false,
            },
            p03: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                BTN_TEXT: '저장',
                modalData:{},
                valid:true,

                ctcpTypeCds: [],
                bussTypeCds: [],

                //v-model
                CTCP_CODE: '',
                BUSS_CODE: '',
                EXCEL_FILE: '',

                CMP_CNSL_ID: '',
                DONG: '', //동
                HOSU: '', //호수
                ADDRESS: '', //동-호수(주소)
                CONTR_CUST_NAME: '',
                CONTR_CUST_PHONE: '',
                CUST_PPST_CD: '', //고객성향코드
                userPpsityCd: [],
                PROC_STAT_CD: '', //상담상태
                cslStatusItems: [],
                csltIds: [],
                TM_CONT: '',
                MKTG_AGRE_YN: false,
                PRSN_AGRE_YN: false,

                alertMsg:{
                    success    : "정상적으로 처리되었습니다.",
                    succDel    : "정상적으로 삭제되었습니다.",
                    errorChk   : "재시도 후 같은 문제가 반복될 시 관리자에게 문의하세요.",
                    error      : "시스템 장애가 일어났습니다. 관리자에게 문의하세요.",
                    saveMsg    : "업로드 하시겠습니까?",
                    chkFile    : "파일을 첨부해주세요.",
                    ctcpChkMsg : "건설사를 선택해주세요.",
                    bussChkMsg : "사업지를 선택해주세요.",
                    rowDataDuplChk : "중복된 데이터가 존재합니다 그래도 저장하시겠습니까?",
                },

                USER_AUTH_BOOL: false,
                USER_AUTH_CHK: '',
                allCodeList: [],

                detaInfoData: {},
            }
        },

        async mounted() {
            this.detaInfoData = {};
            
            if(!this.mixin_isEmpty(this.param)) {
                let type = this.param.type;
                if(!this.mixin_isEmpty(type) && type != 'F') {
                    this.fnTypeBySett(type);
                }
            }

            await this.fnInit();
        },

        computed: {
            initHeaders(){
                return {
                };
            },

            paramData(){
                return {
                };
            }
        },

        methods: {

            async fnGetListData() {

                let requestData = {
                    headers: {
                        SERVICE: this.initHeaders.SERVICE,
                        METHOD: 'list',
                        TYPE: this.initHeaders.TYPE,
                    },
                    sendData:{}
                };

                requestData.headers["URL"] = "/api/hlw/omm/mi/contract/manage/list";

                // sendData 세팅
                requestData.sendData["CMP_CNSL_ID"]=this.CMP_CNSL_ID;
                requestData.sendData["USE_YN"]='Y';

                let responseGridList = await this.common_postCall(requestData);
                let resHeader = responseGridList.HEADER;
                let resData = responseGridList.DATA;

                // 데이터 조회 오류 시 alert
                if (resHeader.ERROR_FLAG) {
                    this.common_alert(this.alertMsg.error, "error")
                    return false;
                }else{

                    if(!this.mixin_isEmpty(resData)) {
                        this.detaInfoData = resData[0];

                        if(!this.mixin_isEmpty(this.detaInfoData.CTCP_CODE)) {
                            this.fnChnCtcpCd(this.detaInfoData.CTCP_CODE);
                        }
    
                        this.detaInfoData.MKTG_AGRE_YN = this.detaInfoData.MKTG_AGRE_YN == 'Y' ? true : false;
                        this.detaInfoData.PRSN_AGRE_YN = this.detaInfoData.PRSN_AGRE_YN == 'Y' ? true : false;
                    }
                }
            },

            async fnTypeBySett(type) {

                if(type == 'U') {
                    /** ROW DATA */
                    if(!this.mixin_isEmpty(this.param)) {
                        this.BTN_TEXT = this.param.btnText;

                        if(!this.mixin_isEmpty(this.param.rowData.item)) {
                            this.detaInfoData = this.param.rowData.item;
                            this.CMP_CNSL_ID = this.detaInfoData.CMP_CNSL_ID;

                            await this.fnGetListData();
                        }
                    }else{
                        this.$emit('hide');
                    }
                    return;
                }
                
                this.detaInfoData = {};
            },

            async fnInit() {
                this.USER_AUTH_CHK = this.user_role.userGroupCd;
                this.USER_ID = this.user_id;

                let pArr = ['HLW_CTCP_CD', 'HLW_CUST_PPST_CD', 'HLW_PROC_STAT_CD'];
                
                this.allCodeList = await this.mixin_common_code_get_all(pArr);

                this.userPpsityCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CUST_PPST_CD', '선택');
                this.cslStatusItems = await this.mixin_common_code_get(this.allCodeList, 'HLW_PROC_STAT_CD', '선택');

                if (this.USER_AUTH_CHK == "SYSTEMMANAGER") {
                    this.USER_AUTH_BOOL = true;
                    this.ctcpTypeCds = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD');
                } else {
                    
                    if(this.USER_AUTH_CHK == 'MIPROMOTER') {
                        this.USER_AUTH_BOOL = true; //기능 권한
                    }else{
                        this.USER_AUTH_BOOL = false; //기능 권한
                    }

                    let userAuths = await this.mixin_buss_ctcp_code_user_auto(this.USER_ID);

                    if (userAuths.length > 0) {
                        for (let i = 0; i < userAuths.length; i++) {
                            this.ctcpTypeCds.push({ CD_NM: userAuths[i].CTCP_NAME, CD: userAuths[i].CTCP_CODE });
                            this.bussTypeCds.push({ CD_NM: userAuths[i].BUSS_NAME, CD: userAuths[i].BUSS_CODE });
                        }
                        this.CTCP_CODE = this.ctcpTypeCds[0].CD;
                        this.BUSS_CODE = this.bussTypeCds[0].CD;
                    }
                }
            },

            async fnChnCtcpCd(ctcpCd) {
                
                this.bussTypeCds=[];
                this.BUSS_CODE = "";

                if (!this.mixin_isEmpty(ctcpCd)) {
                    if (this.USER_AUTH_BOOL && this.USER_AUTH_CHK == "SYSTEMMANAGER") {
                        this.bussTypeCds=await this.mixin_mi_busscode_get(ctcpCd);
                    }else{
                        this.bussTypeCds=await this.mixin_mi_busscode_get(ctcpCd, this.USER_ID);
                    }
                }
            },

            fnExcelUpload() {
                if(this.mixin_isEmpty(this.CTCP_CODE)) {
                    this.common_alert(this.alertMsg.ctcpChkMsg, "chk");
                    return false;
                }

                if(this.mixin_isEmpty(this.BUSS_CODE)) {
                    this.common_alert(this.alertMsg.bussChkMsg, "chk");
                    return false;
                }

                if(this.mixin_isEmpty(this.EXCEL_FILE)) {
                    this.common_alert(this.alertMsg.chkFile, "chk");
                    return false;
                }

                this.common_confirm(this.alertMsg.saveMsg, this.uploadExcel, null, null, null, 'chk');
            },

            callNo() {
                this.$store.commit("alertStore/openAlertDialog", {
                    alertDialogToggle: false,
                });
            },

            async uploadExcel() {
                this.callNo();
                this.token = this.mixin_getToken();
                let excelHeader=[];

                this.param.excelHeaderKeys.forEach((ele, i) => {
                    excelHeader.push(ele)
                });

                let requestData = {
                    headers: {
                        SERVICE:'files',
                        METHOD:'upload',
                        TYPE:'BIZ_SERVICE',
                        MENU_TYPE:'OMM',
                        PART_TYPE:'MI',
                        URL:process.env.VUE_APP_API_URL+"api/files/excel/upload",
                        AUTHORIZATION:this.token,
                        CTCP_CODE:this.CTCP_CODE,
                        BUSS_CODE:this.BUSS_CODE,
                        EXCEL_HEADER:['DONG','HOSU','CONTR_CUST_NAME','CONTR_CUST_PHONE','CUST_RES_NO','ADDRESS'],
                        USER_ID:this.USER_ID,
                        'Content-Type':'multipart/form-data',
                    },
                    sendData:{}
                };

                let vue=this;
                let localSaveKeysObj=[];
                let input=vue.$refs.excelFile.$refs.input;
                let sknObj={};
                const formData = new FormData();
                for (let index = 0; index < input.files.length; index++) {
                    const file = input.files[index];
                    if(input.type === 'file' && file !== undefined) {
                        if(file.name.indexOf('.xls')>0||file.name.indexOf('.xlsx')>0){
                            if(file.name.includes('계약 고객') && file.name.includes('템플릿')) {
                                formData.append('fileList', file);
                                sknObj={};
                                sknObj['key']=input.name;
                                sknObj['fileName']=file.name;
                                localSaveKeysObj.push(sknObj); //업로드할 파일 적재

                                await this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", true);
                            }else{
                                this.common_alert('템플릿 샘플 양식(템플릿명:"계약 고객 양식 템플릿)으로\n 작업 후 업로드 해주세요.', 'chk');
                                return;
                            }
                        }else{
                            this.common_alert('엑셀 파일이 아닙니다.');
                            return;
                        }
                    }
                }
                this.saveKeysObj=localSaveKeysObj;

                if(this.saveKeysObj.length>0){
                    axios.post(requestData.headers.URL, formData, {
                        headers: requestData.headers
                    }).then(response => {
                        this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
                        const returnDatas=response.data;
                        if(returnDatas.responseMessage==='정상'){ //파일 업로드 정상 후 처리
                            const retDatas=returnDatas.data.DATA;
                            this.uploadCallBack(retDatas);
                        }else{
                            return alert(this.alertMsg.error);
                        }
                    }).catch(error => {
                        this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
                        return error.message;
                    })
                }
            },

            uploadCallBack(params) {
                let retDatas = params[0];

                let resultStr = '';
                resultStr += "저장된 총 개수 : "+retDatas.resultCnt;    //총 개수
                resultStr += "\n저장 안 된 개수 : "+retDatas.resultDonCnt;    //저장 안 된 개수
                resultStr += "\n저장 안 된 ROW 번호'S : "+retDatas.resultDonRowNum;    //저장 안 된 로우 번호

                this.common_alert(resultStr);
                this.$emit('listCall');
            },

            fnValidParams() {
                if(this.mixin_isEmpty(this.detaInfoData.CTCP_CODE)) {
                    this.common_alert('건설사를 선택해주세요.', 'chk');
                    return false;
                }else if(this.mixin_isEmpty(this.detaInfoData.BUSS_CODE)) {
                    this.common_alert('사업지를 선택해주세요.', 'chk');
                    return false;
                }else if(this.mixin_isEmpty(this.detaInfoData.DONG) && this.p02) {
                    this.common_alert('동을 적어주세요.', 'chk');
                    return false;
                }else if(this.mixin_isEmpty(this.detaInfoData.HOSU) && this.p02) {
                    this.common_alert('호수를 적어주세요.', 'chk');
                    return false;
                }else if(this.mixin_isEmpty(this.detaInfoData.ADDRESS) && this.p02) {
                    this.common_alert('주소를 적어주세요.', 'chk');
                    return false;
                }else if(this.mixin_isEmpty(this.detaInfoData.CUST_RES_NO) && this.p02) {
                    this.common_alert('생년월일 및 성별을 적어주세요.', 'chk');
                    return false;
                }else if(this.mixin_isEmpty(this.detaInfoData.CONTR_CUST_NAME) && this.p02) {
                    this.common_alert('고객명을 적어주세요.', 'chk');
                    return false;
                }else if(this.mixin_isEmpty(this.detaInfoData.CONTR_CUST_PHONE) && this.p02) {
                    this.common_alert('전화번호를 적어주세요.', 'chk');
                    return false;
                }else if(this.mixin_isEmpty(this.detaInfoData.CUST_NAME) && this.p03) {
                    this.common_alert('카카오톡 고객명을 적어주세요.', 'chk');
                    return false;
                }else if(this.mixin_isEmpty(this.detaInfoData.CUST_PHONE) && this.p03) {
                    this.common_alert('카카오톡 전화번호를 적어주세요.', 'chk');
                    return false;
                }else{
                    return true;
                }
            },

            fnModiConfirm() {

                if(!this.fnValidParams()) {
                    return;
                }

                let modiFunc = null;

                if(!this.mixin_isEmpty(this.param)) {
                    let viewType = this.param.type;
                    modiFunc = this.fnModi;
                }

                this.common_confirm(this.BTN_TEXT+' 하시겠습니까?', modiFunc, null, null, null, 'chk');
            },

            fnGapReplace(param) {
                return param.replaceAll(/[' ']/gi, '').trim();
            },

            /** 데이터 중복 체크 */
            async fnDuplChk() {

                let requestData = {
                    headers: {},
                    sendData:{},
                };

                //header 세팅
                requestData.headers["URL"]="/api/hlw/omm/mi/manage/duplChk";

                requestData.sendData["PART_TYPE"]='MI';
                requestData.sendData["USER_ID"]=this.USER_ID;
                requestData.sendData["CTCP_CODE"]=this.detaInfoData.CTCP_CODE;
                requestData.sendData["BUSS_CODE"]=this.detaInfoData.BUSS_CODE;

                requestData.sendData["DONG"]=this.fnGapReplace(this.detaInfoData.DONG);
                requestData.sendData["HOSU"]=this.fnGapReplace(this.detaInfoData.HOSU);

                let response=await this.common_postCall(requestData, false);
                let header=response.HEADER;
                let resData=response.DATA;

                if (header.ERROR_FLAG) {
                    this.common_alert(this.alertMsg.error,"error");
                }else{
                    if(!this.mixin_isEmpty(resData)) {

                        let duplBool = resData[0].DUPL_BOOL == 'Y' ? true : false;
                        if(duplBool) {   //중복 데이터
                            this.common_confirm(this.alertMsg.rowDataDuplChk, this.fnModi, null, null, null, 'chk');
                        }else{
                            this.fnModi();
                        }
                    }else{
                        this.common_alert(this.alertMsg.errorChk);
                        return;
                    }
                }
            },

            /** 신규등록 및 수정 */
            async fnModi() {

                if(!this.mixin_isEmpty(this.param)) {

                    let viewType = this.param.type;

                    let requestData = {
                        headers: {},
                        sendData:{},
                    };

                    let urlStr = '';
                    if(viewType.includes('I')) {
                        urlStr = 'regist';
                    }else if(viewType.includes('U')) {
                        urlStr = 'update';
                        requestData.sendData["CMP_CNSL_ID"]=this.CMP_CNSL_ID;
                    }else{
                        this.common_alert(this.alertMsg.errorChk, 'error');
                        return;
                    }

                    //header 세팅
                    requestData.headers["URL"]="/api/hlw/omm/mi/manage/"+urlStr;

                    if(this.p02) {
                        requestData.sendData["PART_TYPE"]='MI'; //계약 고객

                        requestData.sendData["DONG"]=this.detaInfoData.DONG;
                        requestData.sendData["HOSU"]=this.detaInfoData.HOSU;

                        requestData.sendData["CONTR_CUST_NAME"]=this.detaInfoData.CONTR_CUST_NAME;
                        requestData.sendData["CONTR_CUST_PHONE"]=this.detaInfoData.CONTR_CUST_PHONE;

                    }else if(this.p03) {
                        requestData.sendData["PART_TYPE"]='PO'; //유입 고객

                        requestData.sendData["CUST_NAME"]=this.detaInfoData.CUST_NAME;
                        requestData.sendData["CUST_PHONE"]=this.detaInfoData.CUST_PHONE;
                    }

                    requestData.sendData["USER_ID"]=this.USER_ID;
                    requestData.sendData["CTCP_CODE"]=this.detaInfoData.CTCP_CODE;
                    requestData.sendData["BUSS_CODE"]=this.detaInfoData.BUSS_CODE;

                    requestData.sendData["ADDRESS"]=this.detaInfoData.ADDRESS;
                    requestData.sendData["CUST_RES_NO"]=this.detaInfoData.CUST_RES_NO;

                    requestData.sendData["CUST_PPST_CD"]=this.detaInfoData.CUST_PPST_CD;
                    requestData.sendData["PROC_STAT_CD"]=this.detaInfoData.PROC_STAT_CD;
                    
                    requestData.sendData["MKTG_AGRE_YN"]=this.detaInfoData.MKTG_AGRE_YN ? 'Y' : 'N';
                    requestData.sendData["PRSN_AGRE_YN"]=this.detaInfoData.PRSN_AGRE_YN ? 'Y' : 'N';

                    let response=await this.common_postCall(requestData, false);
                    let header=response.HEADER;

                    if (header.ERROR_FLAG){
                        this.common_alert(this.alertMsg.error, "error");
                    }else{
                        this.common_alert(this.BTN_TEXT+'됐습니다.');
                        this.$emit('listCall');
                    }
                }
            },
        },
    };
</script>
